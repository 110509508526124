import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Modal,
  DropdownButton,
  Form,
  Row,
  ProgressBar,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Tooltip from "react-bootstrap/Tooltip";
import { popup_alert } from "../../popup_alert";
import moment from "moment";

import { loader } from "../../loader";
import { ENDPOINT } from "../../axios/apiConfig";
import { postData, getData } from "../../axios/apiHelper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import SubLinkListing from "../../Components/Library/CreateChange/SubLinkListing";
import SurveySublinkListing from "./SurveySublinkListing";
import { surveyAxiosInstance } from "./CommonFunctions/CommonFunction";
import { format } from "date-fns";
let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;


const SurveySublink= () => {
    const { state } = useLocation();
  const [allContents, setallContents] = useState([]);
  const [allCodes, setAllCodes] = useState([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState();
  const [articleData, setArticleData] = useState();
  const [libraryData, setLibraryData] = useState([]);
  const [createNewLink, setCreateNewLink] = useState(false);
  const [reRenderFlag, setreRenderFlag] = useState(0);
  const [showSubLinkList, setshowSubLinkList] = useState(false);
  const [linkRenderCount, setLinkRenderCount] = useState(0);
  const [changeConsent, setchangeConsent] = useState([]);
  const [flag, setFlag] = useState(0);
  const [opening_details, setOpeningDetails] = useState([]);
  const [userId, setUserId] = useState();
  const [update, setUpdate] = useState(0);
  const [consentValue, setConsentValue] = useState("");
  const [identifier, setIdentifier] = useState("");

  const [newLink, setLink] = useState({
    delivery: "",
  });

  const [types, setTypes] = useState([
    { value: "Online ", label: "Online Offer" },
  ]);
  const [activeTab, setActiveTab] = useState("docintel-link");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("user_id") != "56Ek4feL/1A8mZgIKQWEqg==") {
      let linktype = types;
      linktype.push(
        { value: "Offline Offer", label: "Offline Offer" },
        { value: "Sunshine", label: "Sunshine" }
      );
      setTypes(linktype);
    }
    getSurveyData();
  }, []);

  useEffect(() => {
    getArticleData();
  }, [selectedSurveyId]);

  const getSurveyData = async () => {
    try {
      loader("show");
      let selectedValue = [];
      let data = {
        user_id: localStorage.getItem("user_id"),
        page: 1,
        search: "",
        license: 0,
        type: "All",
        order: "true",
        selectValue: JSON.stringify(selectedValue),
      };


      let body = data;
      const res = await surveyAxiosInstance.post("/survey/fetch-all-survey-title",{admin_id:18207});
      console.log(res)
      let arr = [];
      let codearr = [];
      Object.entries(res?.data?.data).map(([index, item]) => {
        arr.push({
          value: item.id,
          label: item.survey_title.replace(/(<([^>]+)>)/gi, ""),
        });
        codearr.push({
          value: item.id,
          label: item.unique_code,
        });
        setallContents(arr);
      });
      codearr.sort((a, b) => {
        let x = a.label.toLowerCase();
        let y = b.label.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
      setAllCodes(codearr);
      setLibraryData((oldArray) => [...oldArray, ...res?.data?.data]);

      if (typeof selectedSurveyId === "undefined") {
        if (state?.survey_id) {
          setSelectedSurveyId(state.survey_id);
        }
      }
    } catch (err) {
      console.log("err");
      toast.error("Something went wrong")
    } finally {
      loader("hide");
     
    }
  };

  const onArticleChange = async (event) => {
    setSelectedSurveyId(event.value);
  };

  const createNewLinkClicked = () => {
    setCreateNewLink(true);
  };
  const handleChange = (name, e) => {
    setLink({ ...newLink, [name]: e });
  };

  const handleSubmit = async () => {
    try {
      loader("show");
      let body = {
        survey_id: selectedSurveyId,
        delivery: newLink.delivery,
        identifier: identifier,
      };
      const res = await surveyAxiosInstance.post("/survey/insert-sublink-information", body);
       setLink(prevLink => ({
        ...prevLink,
        delivery: "", // Clear the delivery field
      }));
      setshowSubLinkList(true);
      setLinkRenderCount(prevCount => prevCount + 1); // Increment render count
    } catch (err) {
      console.log("err", err);
      toast.error("Something went wrong")
    } finally {
      loader("hide");
    }
    setCreateNewLink(false);
  };




  const [data, setIsData] = useState([]);

   const getArticleData = async () => {
    try {
      loader("show");
      let res = await surveyAxiosInstance.post("/survey/fetch-survey-data", {
        admin_id: 18207,
        survey_id:selectedSurveyId
      });
      const survey_data = res?.data?.data;
      console.log(res);
      console.log(survey_data)

      if (res) {
        setIsData(survey_data[0]);
      }

      loader("hide");
    } catch (error) {
      loader("hide");
      toast.error("Something went wrong");
    }
  };

  const tabClicked = async (event, id) => {
    setActiveTab(event);
    setFlag(0);

    let normal_data = opening_details;
    setUserId(id);

    let contains_already;

    if (event == "data-tab") {
      normal_data?.filter((data) => {
        if (data?.pdf_id == id) {
          contains_already = true;
          setFlag(1);
        }
      });

      setOpeningDetails(normal_data);

      if (contains_already != true) {
        try {
          let body = {
            pdfId: [id],
          };
          const res = await postData(ENDPOINT.LIBRARYSTATS, body);

          const status = normal_data?.map((datas) => {
            if (datas?.pdf_id == id) {
              return "true";
            } else {
              return "false";
            }
          });
          if (status?.every((ele) => ele == "false")) {
            normal_data?.push({
              pdf_id: id,
              uniqueReader: res?.data?.data[0]?.unique,
              opening: res?.data?.data[0]?.opening,
              registeredReader: res?.data?.data[0]?.reader,
              limit: res?.data?.data[0]?.limit,
              print: res?.data?.data[0]?.print,
              download: res?.data?.data[0]?.download,
              subLink: res?.data?.data[0]?.subLink,
            });
          }

          setOpeningDetails(normal_data);
          setFlag(1);

          setUpdate(update + 1);
        } catch (err) {
          console.log("err");
        } finally {
          loader("hide");
        }
      }
    }
  };

  const onConsentChange = (e, i) => {
    setConsentValue(e);
    let consetValue = e.value;
    let consent = {
      index: i,
      value: consetValue,
    };

    const found = changeConsent.some((el) => el.index === i);
    if (!found) {
      setchangeConsent((oldarray) => [...oldarray, consent]);
    } else {
      const index = changeConsent.findIndex((el) => el.index === i);
      changeConsent[index].value = consetValue;
    }
  };

  const updateConset = async (pdf_id) => {
    try {
      loader("show");
      const index = changeConsent.findIndex((el) => el.index === pdf_id);
      let consent_value = changeConsent[index].value;

      let body = {
        pdfId: pdf_id,
        consentType: consent_value,
      };
      const lib_data_index = libraryData.findIndex((el) => el.id === pdf_id);
      libraryData[lib_data_index].linkType = consent_value;
      const new_data = libraryData;
      setLibraryData(new_data);
      articleData.linkType = consent_value;
      setreRenderFlag(reRenderFlag + 1);
      loader("hide");
      popup_alert({
        visible: "show",
        message: "Your content has been update <br />successfully !",
        type: "success",
        redirect: "",
      });
    } catch (err) {
      console.log("err", err);
      loader("hide");
    }
  };

  const copyToClipboard = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success("content copied to the clipboard!");
    } else {
      unsecuredCopyToClipboard(content);
    }
  };

  const unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      toast.success("content copied to the clipboard!");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };
  function LinkWithTooltip({ id, children, href, tooltip }) {
    return (
      <OverlayTrigger
        overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
        placement="top"
        delayShow={300}
        delayHide={150}
      >
        <a href={href}>{children}</a>
      </OverlayTrigger>
    );
  }

  const onIdentifierChange = (event) => {
    setIdentifier(event.target.value);
  };

  const changeFormatForPrint = (value) => {
    let data = "";
    if (value?.allow_print) {
      data += "Print | ";
    }
    if (value?.allow_download) {
      data += "Download | ";
    }
    if (value?.allow_share) {
      data += "Share | ";
    }
    if (value?.chat_box) {
      data += "Request | ";
    }
    if (data) {
      data = data.trim().slice(0, -1);
    } else {
      data = "N/A";
    }
    return data;
  };
    return (
       <>
       <Col className="right-sidebar">
        <div className="custom-container">
          <Row>
            <div className="top-header">
              <div className="page-title d-flex">
                <h2>SubLinks</h2>
              </div>
              {/* <div className="header-btn">
                <Button
                  className="btn-bordered cancel"
                  onClick={() => navigate("/library-create")}
                >
                  Close
                </Button>
              </div> */}
            </div>
            <div className="create-change-content survey-sublink">
              <div className="form_action">
                <div className="row">
                  <Col className="sublink_left">
                    <h5>
                    Please find the survey you'd like a new subLink for
                    </h5>
                    <div className="product-unit d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label htmlFor="">Survey</label>
                        <Select
                          options={allContents}
                          placeholder="Select survey to create a sublink "
                          onChange={(event) => onArticleChange(event)}
                          value={
                            selectedSurveyId != ""
                              ? allContents[
                                  allContents.findIndex(
                                    (el) => el.value === selectedSurveyId
                                  )
                                ]
                              : ""
                          }
                          className="dropdown-basic-button split-button-dropup"
                          isClearable
                        />
                      </div>
                      <div className="form-group blank">
                        <span>OR</span>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">URL</label>
                        <Select
                          options={allCodes}
                          placeholder="Select survey URL"
                          onChange={(event) => onArticleChange(event)}
                          value={
                            selectedSurveyId != ""
                              ? allCodes[
                                  allCodes.findIndex(
                                    (el) => el.value === selectedSurveyId
                                  )
                                ]
                              : ""
                          }
                          className="dropdown-basic-button split-button-dropup"
                          isClearable
                        />
                      </div>
                    </div>
                    <div className="no_content_selected">
                      <>
                        { data?.length  != "0"  ? (
                          <>
                          <div className="survey-builder">
                            <div className="survey-listing">
                            <div className="library-content-box-layuot">
                            <div class="email_box_block">
                            <div
                              class={
                                data?.is_draft != null && data?.is_draft == "0"
                                  ? "email_box email-draft"
                                  : "email_box approved"
                              }
                            >
                              {data?.is_draft != null &&
                                data?.is_draft == "0" && (
                                  <div class="mail-top-title">
                                    <span>Draft</span>
                                  </div>
                                )}

                              <div class="mail-box-content">
                                <div class="mail-box-content-top">
                                  <div class="mail-box-content-top-view">
                                    {data?.is_draft == "1" && (
                                      <div className="survey_status">
                                        <span>Live</span>
                                      </div>
                                    )}
                                    {data?.is_draft == "2" && (
                                      <div className="survey_status completed">
                                        <span>Completed</span>
                                      </div>
                                    )}

                                    <h5>{data?.survey_title}</h5>
                                    <p>{data?.subtitle}</p>
                                  </div>
                                </div>
                                <div className="tabs-data">
                                  <Tabs defaultActiveKey="link">
                                    <Tab eventKey="link" title="Link">
                                      <div className="survey_tabs_data">
                                        <div className="tab-panel">
                                          <div class="tab-content-links">
                                            <a
                                              href=""
                                              class="doc-link"
                                              target="_blank"
                                            >
                                              https://informed.pro/survey/
                                              {data?.unique_code}
                                            </a>
                                            {data?.is_draft ? (
                                              <span
                                                class="copy-content"
                                                // onClick={() =>
                                                //   copyHandler(data.unique_code)
                                                // }
                                              >
                                                <img
                                                  src="http://localhost:3000/componentAssets/images/copy-content.svg"
                                                  alt="Copy"
                                                />
                                              </span>
                                            ) : (
                                              <span class="copy-content">
                                                <img
                                                  src="http://localhost:3000/componentAssets/images/copy-content-disabled.svg"
                                                  alt="Copy"
                                                />
                                              </span>
                                            )}
                                            {data?.is_draft ? (
                                              <div className="tab-content-qr">
                                                <img
                                                  src={
                                                    path_image +
                                                    "qr-code-icon.svg"
                                                  }
                                                  alt="QR"
                                                />
                                                <img
                                                  src={
                                                    path_image +
                                                    "download-icon.svg"
                                                  }
                                                  alt="Download"
                                                />
                                              </div>
                                            ) : (
                                              <div className="tab-content-qr">
                                                <img
                                                  src={
                                                    path_image +
                                                    "qr-code-icon-disabled.svg"
                                                  }
                                                  alt="QR"
                                                />
                                                <img
                                                  src={
                                                    path_image +
                                                    "download-icon-disabled.svg"
                                                  }
                                                  alt="Download"
                                                />
                                              </div>
                                            )}
                                          </div>
                                          <ul className="survey-consent">
                                            <li className="d-flex align-items-center">
                                              <h6 className="tab-content-title">
                                                Consent
                                              </h6>
                                              {data?.survey_consent != "" ? (
                                                <h6>{data?.survey_consent}</h6>
                                              ) : (
                                                <h6>N/A</h6>
                                              )}
                                            </li>
                                            <li className="d-flex align-items-center">
                                              <h6 className="tab-content-title">
                                                Creator
                                              </h6>
                                              <h6>{data?.creator_name}</h6>
                                            </li>
                                          </ul>
                                          <div class="mailbox-tags">
                                            <ul>
                                              {JSON?.parse(data?.tags)?.length >
                                              0 ? (
                                                JSON?.parse(data?.tags).map(
                                                  (tag, index) => (
                                                    <li key={index}>{tag}</li>
                                                  )
                                                )
                                              ) : (
                                                <li>N/A</li>
                                              )}
                                            </ul>
                                          </div>
                                          <div class="mail-time">
                                            <span>
                                              {format(
                                                new Date(data?.date),
                                                "MMMM d, yyyy '|' h:mm a"
                                              )}
                                            </span>
                                          </div>

                                          {data?.is_draft != null &&
                                          data?.is_draft == "0" ? (
                                            <div class="mail-stats">
                                              <ul>
                                                <li>
                                                  <div
                                                    class="mail-status irts"
                                                    title="Sublinks"
                                                  >
                                                    <svg
                                                      width="16"
                                                      height="16"
                                                      viewBox="0 0 16 16"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M9.59862 1.09837L6.34653 4.35044C6.34025 4.35669 6.33634 4.36428 6.33009 4.37059C7.13125 4.25391 7.95428 4.33391 8.71722 4.63141L10.9244 2.42422C11.6556 1.693 12.8448 1.693 13.5761 2.42422C14.3073 3.15537 14.3073 4.34466 13.5761 5.07581C13.4514 5.20056 10.136 8.51597 10.324 8.32787C9.587 9.06494 8.37787 9.03341 7.67234 8.32787C7.30694 7.96247 6.712 7.96247 6.34653 8.32787L5.77734 8.89706C5.93522 9.16531 6.11622 9.42344 6.34653 9.65375C7.73528 11.0425 10.1257 11.1534 11.6297 9.67019C11.636 9.66394 11.6435 9.66 11.6498 9.65375L14.9019 6.40169C16.3663 4.93719 16.3663 2.56287 14.9019 1.09837C13.4374 -0.366125 11.0631 -0.366125 9.59862 1.09837Z"
                                                        fill="#97B6CF"
                                                        fill-opacity="0.6"
                                                      />
                                                      <path
                                                        d="M7.29013 11.3608L5.07582 13.5751C4.34466 14.3063 3.15538 14.3063 2.42423 13.5751C1.69301 12.8439 1.69301 11.6546 2.42423 10.9234C2.54891 10.7987 5.87141 7.47623 5.68338 7.66426C6.42038 6.92726 7.62951 6.95873 8.33504 7.66426C8.70044 8.02973 9.29541 8.02973 9.66085 7.66426L10.23 7.09507C10.0722 6.82682 9.89116 6.56869 9.66085 6.33844C8.27476 4.95229 5.88607 4.83435 4.3777 6.32198C4.37141 6.32823 4.36385 6.33216 4.35754 6.33844L1.09835 9.59763C-0.366086 11.0621 -0.366148 13.4364 1.09835 14.9009C2.56285 16.3654 4.93723 16.3654 6.40166 14.9009L9.66082 11.6417C9.6671 11.6355 9.67101 11.6279 9.67726 11.6216C8.8761 11.7383 8.0531 11.6583 7.29013 11.3608Z"
                                                        fill="#97B6CF"
                                                        fill-opacity="0.6"
                                                      />
                                                    </svg>
                                                  </div>
                                                  <span>0</span>
                                                </li>
                                                <li>
                                                  <div
                                                    class="mail-status mail-hit"
                                                    title="Link opening"
                                                  >
                                                    <svg
                                                      width="14"
                                                      height="16"
                                                      viewBox="0 0 14 16"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M2.96391 5.30631C2.85416 4.93468 2.74879 4.56243 2.6696 4.20577C2.14894 3.89774 1.79477 3.33718 1.79477 2.68932C1.79477 1.71473 2.58729 0.922837 3.56126 0.922837C4.53522 0.922837 5.32774 1.71535 5.32774 2.68932C5.32774 2.82338 5.30966 2.95246 5.2816 3.07779C5.45058 3.45004 5.58713 3.86906 5.70685 4.29493C6.04356 3.84599 6.25058 3.29415 6.25058 2.68932C6.25058 1.20343 5.04715 0 3.56126 0C2.07536 0 0.872559 1.20343 0.872559 2.68932C0.872559 3.96882 1.76734 5.03445 2.96391 5.30631Z"
                                                        fill="#C8D1D9"
                                                      ></path>
                                                      <path
                                                        d="M1.10616 11.673C1.76898 10.9566 2.51286 11.2372 3.50865 11.3887C4.36415 11.5203 5.20655 11.2802 5.15043 10.8182C5.06189 10.0705 4.93718 9.73632 4.65347 8.76797C4.42713 7.9979 3.99751 6.6099 3.60655 5.28301C3.08278 3.50779 2.93126 2.68348 3.62837 2.47771C4.37974 2.25885 4.8106 3.32635 5.20094 4.80663C5.64552 6.49143 5.87935 7.23531 6.01029 7.19603C6.241 7.12993 5.92549 6.40912 6.52907 6.23141C7.28356 6.01193 7.42946 6.60179 7.64084 6.54256C7.85222 6.47896 7.78052 5.88161 8.38223 5.70577C8.98706 5.53118 9.29073 6.27568 9.54014 6.20148C9.78706 6.12853 9.78145 5.85978 10.1543 5.75316C10.5278 5.64217 11.9333 6.27132 12.7376 9.01925C13.7472 12.4743 12.6098 13.1165 12.9546 14.2863L8.44833 15.9998C8.08356 15.1224 6.9537 15.0576 5.95417 14.4983C4.94716 13.9315 4.26314 12.8272 1.63866 12.8808C0.6516 12.9008 0.698366 12.1139 1.10616 11.673Z"
                                                        fill="#C8D1D9"
                                                      ></path>
                                                    </svg>
                                                  </div>
                                                  <span>0</span>
                                                </li>
                                                <li>
                                                  <div
                                                    class="mail-status mail_view"
                                                    title="Started"
                                                  >
                                                    <svg
                                                      width="17"
                                                      height="16"
                                                      viewBox="0 0 17 16"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M6.46443 6.80015C8.34247 6.80015 9.86464 5.27769 9.86464 3.39993C9.86464 1.52217 8.34218 0 6.46443 0C4.58667 0 3.06363 1.52246 3.06363 3.40022C3.06363 5.27797 4.58667 6.80015 6.46443 6.80015Z"
                                                        fill="#97B6CF"
                                                        fill-opacity="0.6"
                                                      />
                                                      <path
                                                        d="M7.90674 7.0319H5.02153C2.62095 7.0319 0.667969 8.98517 0.667969 11.3858V14.9141L0.676938 14.9694L0.919976 15.0455C3.2109 15.7613 5.20121 16 6.8394 16C8.20976 16 9.3334 15.8327 10.1793 15.6368C9.44888 14.8611 9.0013 13.8162 9.0013 12.6667C9.0013 10.9692 9.97731 9.4997 11.3988 8.78873C10.6046 7.7232 9.33488 7.0319 7.90674 7.0319Z"
                                                        fill="#97B6CF"
                                                        fill-opacity="0.6"
                                                      />
                                                      <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M13.3346 9.33333C11.4938 9.33333 10.0013 10.8258 10.0013 12.6667C10.0013 14.5076 11.4938 16 13.3346 16C15.1755 16 16.668 14.5076 16.668 12.6667C16.668 10.8258 15.1755 9.33333 13.3346 9.33333ZM11.8679 12.2998C11.5918 12.2998 11.3679 12.5237 11.3679 12.7998C11.3679 13.0759 11.5918 13.2998 11.8679 13.2998H14.8679C15.1441 13.2998 15.3679 13.0759 15.3679 12.7998C15.3679 12.5237 15.1441 12.2998 14.8679 12.2998H11.8679Z"
                                                        fill="#97B6CF"
                                                        fill-opacity="0.6"
                                                      />
                                                    </svg>
                                                  </div>
                                                  <span>0 </span>
                                                </li>
                                                <li>
                                                  <div
                                                    class="mail-status mail_click"
                                                    title="Completed"
                                                  >
                                                    <svg
                                                      width="17"
                                                      height="16"
                                                      viewBox="0 0 17 16"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M6.46443 6.80015C8.34247 6.80015 9.86464 5.27769 9.86464 3.39993C9.86464 1.52217 8.34218 0 6.46443 0C4.58667 0 3.06363 1.52246 3.06363 3.40022C3.06363 5.27797 4.58667 6.80015 6.46443 6.80015Z"
                                                        fill="#97B6CF"
                                                        fill-opacity="0.6"
                                                      />
                                                      <path
                                                        d="M7.90674 7.0319H5.02153C2.62095 7.0319 0.667969 8.98517 0.667969 11.3858V14.9141L0.676938 14.9694L0.919976 15.0455C3.2109 15.7613 5.20121 16 6.8394 16C8.20976 16 9.3334 15.8327 10.1793 15.6368C9.44888 14.8611 9.0013 13.8162 9.0013 12.6667C9.0013 10.9692 9.97731 9.4997 11.3988 8.78873C10.6046 7.7232 9.33488 7.0319 7.90674 7.0319Z"
                                                        fill="#97B6CF"
                                                        fill-opacity="0.6"
                                                      />
                                                      <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M13.3346 9.33333C11.4938 9.33333 10.0013 10.8258 10.0013 12.6667C10.0013 14.5076 11.4938 16 13.3346 16C15.1755 16 16.668 14.5076 16.668 12.6667C16.668 10.8258 15.1755 9.33333 13.3346 9.33333ZM11.8679 12.2998C11.5918 12.2998 11.3679 12.5237 11.3679 12.7998C11.3679 13.0759 11.5918 13.2998 11.8679 13.2998H14.8679C15.1441 13.2998 15.3679 13.0759 15.3679 12.7998C15.3679 12.5237 15.1441 12.2998 14.8679 12.2998H11.8679Z"
                                                        fill="#97B6CF"
                                                        fill-opacity="0.6"
                                                      />
                                                    </svg>
                                                  </div>
                                                  <span>0 </span>
                                                </li>
                                              </ul>
                                            </div>
                                          ) : (
                                            <div class="mail-stats">
                                              <ul>
                                                <li>
                                                  <div
                                                    class="mail-status mail_send"
                                                    title="Sublinks"
                                                  >
                                                    <svg
                                                      width="16"
                                                      height="16"
                                                      viewBox="0 0 16 16"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M9.59862 1.09837L6.34653 4.35044C6.34025 4.35669 6.33634 4.36428 6.33009 4.37059C7.13125 4.25391 7.95428 4.33391 8.71722 4.63141L10.9244 2.42422C11.6556 1.693 12.8448 1.693 13.5761 2.42422C14.3073 3.15537 14.3073 4.34466 13.5761 5.07581C13.4514 5.20056 10.136 8.51597 10.324 8.32787C9.587 9.06494 8.37787 9.03341 7.67234 8.32787C7.30694 7.96247 6.712 7.96247 6.34653 8.32787L5.77734 8.89706C5.93522 9.16531 6.11622 9.42344 6.34653 9.65375C7.73528 11.0425 10.1257 11.1534 11.6297 9.67019C11.636 9.66394 11.6435 9.66 11.6498 9.65375L14.9019 6.40169C16.3663 4.93719 16.3663 2.56287 14.9019 1.09837C13.4374 -0.366125 11.0631 -0.366125 9.59862 1.09837Z"
                                                        fill="#97B6CF"
                                                        fill-opacity="1"
                                                      ></path>
                                                      <path
                                                        d="M7.29013 11.3608L5.07582 13.5751C4.34466 14.3063 3.15538 14.3063 2.42423 13.5751C1.69301 12.8439 1.69301 11.6546 2.42423 10.9234C2.54891 10.7987 5.87141 7.47623 5.68338 7.66426C6.42038 6.92726 7.62951 6.95873 8.33504 7.66426C8.70044 8.02973 9.29541 8.02973 9.66085 7.66426L10.23 7.09507C10.0722 6.82682 9.89116 6.56869 9.66085 6.33844C8.27476 4.95229 5.88607 4.83435 4.3777 6.32198C4.37141 6.32823 4.36385 6.33216 4.35754 6.33844L1.09835 9.59763C-0.366086 11.0621 -0.366148 13.4364 1.09835 14.9009C2.56285 16.3654 4.93723 16.3654 6.40166 14.9009L9.66082 11.6417C9.6671 11.6355 9.67101 11.6279 9.67726 11.6216C8.8761 11.7383 8.0531 11.6583 7.29013 11.3608Z"
                                                        fill="#97B6CF"
                                                        fill-opacity="1"
                                                      ></path>
                                                    </svg>
                                                  </div>
                                                  <span>0</span>
                                                </li>
                                                <li>
                                                  <div
                                                    class="mail-status mail-hit"
                                                    title="Link opening"
                                                  >
                                                    <svg
                                                      width="14"
                                                      height="16"
                                                      viewBox="0 0 14 16"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M2.96391 5.30631C2.85416 4.93468 2.74879 4.56243 2.6696 4.20577C2.14894 3.89774 1.79477 3.33718 1.79477 2.68932C1.79477 1.71473 2.58729 0.922837 3.56126 0.922837C4.53522 0.922837 5.32774 1.71535 5.32774 2.68932C5.32774 2.82338 5.30966 2.95246 5.2816 3.07779C5.45058 3.45004 5.58713 3.86906 5.70685 4.29493C6.04356 3.84599 6.25058 3.29415 6.25058 2.68932C6.25058 1.20343 5.04715 0 3.56126 0C2.07536 0 0.872559 1.20343 0.872559 2.68932C0.872559 3.96882 1.76734 5.03445 2.96391 5.30631Z"
                                                        fill="#0066BE"
                                                      ></path>
                                                      <path
                                                        d="M1.10616 11.673C1.76898 10.9566 2.51286 11.2372 3.50865 11.3887C4.36415 11.5203 5.20655 11.2802 5.15043 10.8182C5.06189 10.0705 4.93718 9.73632 4.65347 8.76797C4.42713 7.9979 3.99751 6.6099 3.60655 5.28301C3.08278 3.50779 2.93126 2.68348 3.62837 2.47771C4.37974 2.25885 4.8106 3.32635 5.20094 4.80663C5.64552 6.49143 5.87935 7.23531 6.01029 7.19603C6.241 7.12993 5.92549 6.40912 6.52907 6.23141C7.28356 6.01193 7.42946 6.60179 7.64084 6.54256C7.85222 6.47896 7.78052 5.88161 8.38223 5.70577C8.98706 5.53118 9.29073 6.27568 9.54014 6.20148C9.78706 6.12853 9.78145 5.85978 10.1543 5.75316C10.5278 5.64217 11.9333 6.27132 12.7376 9.01925C13.7472 12.4743 12.6098 13.1165 12.9546 14.2863L8.44833 15.9998C8.08356 15.1224 6.9537 15.0576 5.95417 14.4983C4.94716 13.9315 4.26314 12.8272 1.63866 12.8808C0.6516 12.9008 0.698366 12.1139 1.10616 11.673Z"
                                                        fill="#0066BE"
                                                      ></path>
                                                    </svg>
                                                  </div>
                                                  <span>0</span>
                                                </li>
                                                <li>
                                                  <div
                                                    class="mail-status mail_view"
                                                    title="Started"
                                                  >
                                                    <svg
                                                      width="17"
                                                      height="16"
                                                      viewBox="0 0 17 16"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M6.46443 6.80015C8.34247 6.80015 9.86464 5.27769 9.86464 3.39993C9.86464 1.52217 8.34218 0 6.46443 0C4.58667 0 3.06363 1.52246 3.06363 3.40022C3.06363 5.27797 4.58667 6.80015 6.46443 6.80015Z"
                                                        fill="#FAC755"
                                                        fill-opacity="1"
                                                      ></path>
                                                      <path
                                                        d="M7.90674 7.0319H5.02153C2.62095 7.0319 0.667969 8.98517 0.667969 11.3858V14.9141L0.676938 14.9694L0.919976 15.0455C3.2109 15.7613 5.20121 16 6.8394 16C8.20976 16 9.3334 15.8327 10.1793 15.6368C9.44888 14.8611 9.0013 13.8162 9.0013 12.6667C9.0013 10.9692 9.97731 9.4997 11.3988 8.78873C10.6046 7.7232 9.33488 7.0319 7.90674 7.0319Z"
                                                        fill="#FAC755"
                                                        fill-opacity="1"
                                                      ></path>
                                                      <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M13.3346 9.33333C11.4938 9.33333 10.0013 10.8258 10.0013 12.6667C10.0013 14.5076 11.4938 16 13.3346 16C15.1755 16 16.668 14.5076 16.668 12.6667C16.668 10.8258 15.1755 9.33333 13.3346 9.33333ZM11.8679 12.2998C11.5918 12.2998 11.3679 12.5237 11.3679 12.7998C11.3679 13.0759 11.5918 13.2998 11.8679 13.2998H14.8679C15.1441 13.2998 15.3679 13.0759 15.3679 12.7998C15.3679 12.5237 15.1441 12.2998 14.8679 12.2998H11.8679Z"
                                                        fill="#FAC755"
                                                        fill-opacity="1"
                                                      ></path>
                                                    </svg>
                                                  </div>
                                                  <span>0 </span>
                                                </li>
                                                <li>
                                                  <div
                                                    class="mail-status mail_click"
                                                    title="Completed"
                                                  >
                                                    <svg
                                                      width="17"
                                                      height="16"
                                                      viewBox="0 0 17 16"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M6.46443 6.80015C8.34247 6.80015 9.86464 5.27769 9.86464 3.39993C9.86464 1.52217 8.34218 0 6.46443 0C4.58667 0 3.06363 1.52246 3.06363 3.40022C3.06363 5.27797 4.58667 6.80015 6.46443 6.80015Z"
                                                        fill="#39CABC"
                                                        fill-opacity="1"
                                                      ></path>
                                                      <path
                                                        d="M7.90674 7.0319H5.02153C2.62095 7.0319 0.667969 8.98517 0.667969 11.3858V14.9141L0.676938 14.9694L0.919976 15.0455C3.2109 15.7613 5.20121 16 6.8394 16C8.20976 16 9.3334 15.8327 10.1793 15.6368C9.44888 14.8611 9.0013 13.8162 9.0013 12.6667C9.0013 10.9692 9.97731 9.4997 11.3988 8.78873C10.6046 7.7232 9.33488 7.0319 7.90674 7.0319Z"
                                                        fill="#39CABC"
                                                        fill-opacity="1"
                                                      ></path>
                                                      <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M13.3346 9.33333C11.4938 9.33333 10.0013 10.8258 10.0013 12.6667C10.0013 14.5076 11.4938 16 13.3346 16C15.1755 16 16.668 14.5076 16.668 12.6667C16.668 10.8258 15.1755 9.33333 13.3346 9.33333ZM11.8679 12.2998C11.5918 12.2998 11.3679 12.5237 11.3679 12.7998C11.3679 13.0759 11.5918 13.2998 11.8679 13.2998H14.8679C15.1441 13.2998 15.3679 13.0759 15.3679 12.7998C15.3679 12.5237 15.1441 12.2998 14.8679 12.2998H11.8679Z"
                                                        fill="#39CABC"
                                                        fill-opacity="1"
                                                      ></path>
                                                    </svg>
                                                  </div>
                                                  <span>0 </span>
                                                </li>
                                              </ul>
                                            </div>
                                          )}
                                        </div>
                                        <div class="mailbox-buttons">
                                          <div className="send_new">
                                            <Button className="btn-bordered send-new disabled">
                                              Analytics
                                            </Button>
                                          </div>
                                          <div class="mailbox-buttons-list">
                                            <Button
                                              className="send btn-bordered"
                                              // onClick={(e) =>
                                              //   editHandler(
                                              //     e,
                                              //     data?.current_route,
                                              //     data
                                              //   )
                                              // }
                                            >
                                              Edit
                                            </Button>
                                            <Button className="edit btn-filled">
                                              Preview
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab>
                                    <Tab
                                      eventKey="sublinks"
                                      title="Sublinks"
                                      className="change-tab flex-column justify-content-between"
                                    >
                                      <div className="survey_tabs_data">
                                        <div className="data-main-box change-tab-main-box tab-panel">
                                          <ul className="tab-mail-list data change">
                                            <li>
                                              <h6 className="tab-content-title">
                                                SubLinks
                                              </h6>
                                              <div className="select-dropdown-wrapper">
                                                <div className="select">
                                                  <Select
                                                    aria-label="SSelect Sublink"
                                                    className="dropdown-basic-button split-button-dropup"
                                                    name="surveyCreator"
                                                    placeholder="Select Sublink"
                                                    // options={selectOptions}
                                                  />
                                                  <Button>Copy</Button>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <h6 className="tab-content-title">
                                                QR Codes
                                              </h6>
                                              <div className="select-dropdown-wrapper">
                                                <div className="select">
                                                  <Select
                                                    aria-label="Select Sublink"
                                                    className="dropdown-basic-button split-button-dropup"
                                                    name="surveyCreator"
                                                    placeholder="Select Sublink"
                                                    // options={selectOptions}
                                                  />
                                                  <Button>Copy</Button>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>

                                        <div class="mailbox-buttons justify-content-end">
                                          <div className="send_new">
                                            <Button className="btn-bordered send-new">
                                              New Sublink
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab>
                                    <Tab eventKey="setting" title="Setting">
                                      <div className="survey_tabs_data survey-setting">
                                        <div class="d-flex align-items-center justify-content-start">
                                          {data?.is_draft != 1 ? (
                                            <>
                                              {" "}
                                              <p class="option-heading">
                                                Status:{" "}
                                                <img
                                                  src={
                                                    path_image +
                                                    "info_circle_icon.svg"
                                                  }
                                                  alt=""
                                                />
                                              </p>
                                              <div class="form-switch">
                                                {/* <input type="checkbox" id="custom-switch" class="form-check-input"/> */}
                                                <span>Completed</span>
                                                <Form.Check
                                                  inline
                                                  label="Live"
                                                  name="group1"
                                                  type="checkbox"
                                                />
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <div class="mailbox-buttons justify-content-end">
                                          <div className="send_new">
                                            <Button className="btn-bordered send-new">
                                              Duplicate Survey
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab>
                                  </Tabs>
                                </div>
                                {/* {deletestatus && (
                                  <div className="dlt_btn">
                                    <button
                                      onClick={(e) =>
                                        showConfirmationPopup(data.survey_id)
                                      }
                                    >
                                      <img
                                        src={path + "delete.svg"}
                                        alt="Delete Row"
                                      />
                                    </button>
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                            </div>
                            </div>
                          </div>
                           
                          </>
                        ) : (
                          <h3>No survey selected yet!</h3>
                        )}
                      </>
                    </div>
                  </Col>
                  <Col className="sublink_right d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>SubLinks:</h5>
                      <Button
                        className={
                          !selectedSurveyId
                            ? "btn-filled btn-disabled"
                            : "btn-filled"
                        }
                        onClick={createNewLinkClicked}
                      >
                        Create New Link +
                      </Button>
                    </div>
                    <SurveySublinkListing 
                      survey_id={selectedSurveyId}
                      render={showSubLinkList}
                      count={linkRenderCount}
                    />
                  </Col>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Col>

      <Modal show={createNewLink} className="send-confirm" id="download-qr">
        <Modal.Header>
          <h5 className="modal-title" id="staticBackdropLabel">
            Create New Link
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {
              setCreateNewLink(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="">Delivery</label>
            <DropdownButton
              className={
                "dropdown-basic-button split-button-dropup " +
                (newLink?.delivery ? "addval" : "")
              }
              title={
                newLink?.delivery ? newLink?.delivery : "Select delivery type"
              }
              name="delivery"
              onSelect={(e) => handleChange("delivery", e)}
            >
              <div className="scroll_div delivery_popup">
                <div className="scroll_div_inset">
                  <Dropdown.Item
                    eventKey="Email"
                    className={newLink?.delivery == "Email" ? "active" : ""}
                  >
                    Email
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="InforMedGO"
                    className={
                      newLink?.delivery == "InforMedGO" ? "active" : ""
                    }
                  >
                    InforMedGO
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="Social"
                    className={newLink?.delivery == "Social" ? "active" : ""}
                  >
                    Social
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey="Website"
                    className={newLink?.delivery == "Website" ? "active" : ""}
                  >
                    Website
                  </Dropdown.Item>
                </div>
              </div>
            </DropdownButton>
          </div>

          <div className="form-group">
            <label htmlFor="">Identifier</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              onChange={(event) => onIdentifierChange(event)}
            />
          </div>
        </Modal.Body>

        <div className="modal-footer">
          <button
            type="button"
            className={
              !(newLink?.delivery && identifier.trim().length > 0)
                ? "btn btn-primary save btn-filled btn-disabled"
                : "btn btn-primary save btn-filled"
            }
            onClick={() => handleSubmit()}
          >
            Apply
          </button>
        </div>
      </Modal>
       
       
       </> 
    )
}

export default SurveySublink

